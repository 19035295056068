import { EmailAlreadyValidatedError, EmailInUseError, EntityAlreadyExistsError, InvalidCredentialsError, InvalidEmailValidationTokenError, InvalidFileTypeError, NotFoundError, RateLimitError, RefreshTokenExpiredError, RefreshTokenInvalidError, UnexpectedError, } from "@for-it/domain-lib";
import { InvalidMediasArrayError } from "./use-cases/product/delete-product-multimedias.js";
export const DomainErrors = {
    UnexpectedError: UnexpectedError,
    InvalidCredentialsError: InvalidCredentialsError,
    EmailInUseError: EmailInUseError,
    EntityAlreadyExistsError: EntityAlreadyExistsError,
    NotFoundError: NotFoundError,
    RateLimitError: RateLimitError,
    EmailAlreadyValidatedError: EmailAlreadyValidatedError,
    InvalidEmailValidationTokenError: InvalidEmailValidationTokenError,
    InvalidFileTypeError: InvalidFileTypeError,
    InvalidMediasArrayError: InvalidMediasArrayError,
    RefreshTokenExpiredError: RefreshTokenExpiredError,
    InvalidRefreshTokenError: RefreshTokenInvalidError,
};
