import { TaggedError } from "../tagged-error.js";
export class AccessTokenExpiredError extends TaggedError {
    constructor() {
        super("AccessTokenExpiredError");
    }
}
export class RefreshTokenExpiredError extends TaggedError {
    constructor() {
        super("RefreshTokenExpiredError");
    }
}
export class AccessTokenInvalidError extends TaggedError {
    constructor() {
        super("InvalidAccessTokenError");
    }
}
export class RefreshTokenInvalidError extends TaggedError {
    constructor() {
        super("InvalidRefreshTokenError");
    }
}
