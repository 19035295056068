import { isError } from "@for-it/domain-lib";
import { Anchor, Icon, useCurrentUser } from "@for-it/web-lib";
import { useEffect, useState } from "react";
import { Routes } from "../../routes.tsx";
import { useNavigate } from "../../utils/routing-hooks.ts";
import { useAPI } from "../../utils/rpc-hooks.ts";

export const HeaderLanding = () => {
    const currentUser = useCurrentUser();
    const api = useAPI();

    const [showProducts, setShowProducts] = useState(false);

    async function getProducts() {
        const result = await api.call("listProducts");
        if (!isError(result) && result.length > 0) {
            setShowProducts(true);
        }
    }

    useEffect(() => {
        getProducts();
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    function goSection(id: string, url?: string) {
        const section = document.getElementById(id);
        if (section) {
            const sectionTop = section.offsetTop;
            window.scrollTo({
                top: sectionTop - 100,
                behavior: "smooth",
            });
        } else if (url) {
            navigate.toUrl(url);
        }
    }

    return (
        <header className="bg-[#110A26] flex justify-center z-30 py-3 sticky top-0 w-full">
            <div className="container px-4 lg:px-24">
                <div className="flex items-center justify-between h-25">
                    <div
                        className="flex items-center cursor-pointer"
                        onClick={() => goSection("home", Routes.HOME.path())}
                    >
                        <img
                            src="/images/icon-forit.svg"
                            alt="ForIt Logo"
                            className="h-[57px] w-auto"
                        />
                        <span className="font-nunito-black text-[#EBECF0] text-[34.85px] p-2 md:text-[48.36px]">
                            forIT
                        </span>
                    </div>
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-white focus:outline-none text-4xl"
                        >
                            <Icon name="bx-menu" className=""></Icon>
                        </button>
                    </div>
                    <nav
                        className={`${
                            isOpen ? "block" : "hidden"
                        } absolute top-full left-0 w-full bg-[#110A26] md:relative md:flex md:w-auto md:bg-transparent`}
                    >
                        <div className="flex flex-col md:flex-row md:items-center md:gap-6 p-4 md:p-0 md:m-4 pr-10">
                            <button
                                onClick={() =>
                                    goSection(
                                        "services",
                                        Routes.HOME.path() + "#services",
                                    )
                                }
                                className="text-white font-nunito-bold text-[18px] p-2 py-3 cursor-pointer"
                            >
                                Servicios
                            </button>
                            {showProducts && (
                                <button
                                    onClick={() =>
                                        goSection(
                                            "products",
                                            Routes.HOME.path() + "#products",
                                        )
                                    }
                                    className="text-white font-nunito-bold text-[18px] p-2 py-3 cursor-pointer"
                                >
                                    Productos
                                </button>
                            )}
                            <button
                                onClick={() =>
                                    goSection(
                                        "valores-agregados",
                                        Routes.HOME.path() +
                                            "#valores-agregados",
                                    )
                                }
                                className="text-white font-nunito-bold text-[18px] p-2 py-3 cursor-pointer"
                            >
                                Valores Agregados
                            </button>
                            <button
                                onClick={() =>
                                    navigate.toRoute(Routes.TALENT_JOB.path())
                                }
                                className="text-white font-nunito-bold text-[18px] p-2 py-3 cursor-pointer"
                            >
                                Talento Job-ready
                            </button>
                            {currentUser && (
                                <Anchor
                                    url={Routes.ADMIN_LIST_PRODUCT.path()}
                                    className="text-white p-2 py-3"
                                >
                                    <p className="font-nunito-bold border rounded-lg p-2">
                                        Dashboard
                                    </p>
                                </Anchor>
                            )}
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};
