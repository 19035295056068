import { PropsWithChildren } from "react";
import Footer from "../components/footer.tsx";
import { HeaderLanding } from "../components/home/landing-header.tsx";
import { LandingWhatsAppButton } from "../components/ui/landing-whatsaap-button.tsx";

export default function HomeLayout({ children }: PropsWithChildren) {
    return (
        <>
            <HeaderLanding />
            <LandingWhatsAppButton />
            <main>{children}</main>
            <Footer />
        </>
    );
}
