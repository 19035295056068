/**
 * In this file, we define all the constants that we use in the frontend.
 * An example of a constant is an external URL, the name of the application, the name of a local storage key, etc.
 */
export const APP_NAME = "For-It Framework";
export const FORIT_EMAIL = import.meta.env.VITE_FORIT_EMAIL;
export const WSP_NUMBER = import.meta.env.VITE_FORIT_WSP_NUMBER;
export const WSP_MESSAGE =
    import.meta.env.VITE_WSP_MESSAGE ||
    "Hola, me gustaría obtener más información!.";
