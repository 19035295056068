import { Button } from "@for-it/web-lib";
import { WSP_MESSAGE, WSP_NUMBER } from "../../constants.ts";

export const LandingWhatsAppButton = () => {
    const handleClick = () => {
        window.open(
            `https://wa.me/${WSP_NUMBER}?text=${encodeURIComponent(WSP_MESSAGE)}`,
            "_blank",
        );
    };

    return (
        <Button
            className="fixed bottom-5 right-5 cursor-pointer z-50 "
            color="none"
            flavor="clear"
            aria-label="Contactar por WhatsApp"
            onClick={handleClick}
        >
            <div className="rounded-[50%] bg-green-500 p-4">
                <img
                    src="/images/whatsapp-icon.svg"
                    alt="WhatsApp button"
                    className="w-10 h-10 md:w-12 md:h-12 lg:w-13 lg:h-13 object-contain"
                />
            </div>
        </Button>
    );
};
