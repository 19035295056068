import {
    ModalProvider,
    PageModules,
    RoutingProvider,
    RpcClientImplementation,
    RpcClientProvider,
    parseRoutes,
} from "@for-it/web-lib";
import { DomainErrors } from "app-domain";
import { StrictMode } from "react";
import { Toaster } from "react-hot-toast";
import { API_HOST } from "./environment.ts";
import { Routes } from "./routes.tsx";

import "@for-it/web-lib/dist/style.css";
import "./index.css";

const rpcClient = new RpcClientImplementation({
    apiHost: API_HOST,
    errorClasses: DomainErrors,
});

const pageModules = import.meta.glob("./pages/**/*.tsx") as PageModules;

const generatedRoutes = parseRoutes(Routes, pageModules);

export interface AppProps {
    url: string;
}

export default function App({ url }: AppProps) {
    return (
        <StrictMode>
            <RpcClientProvider rpcService={rpcClient}>
                <ModalProvider>
                    <RoutingProvider
                        routes={generatedRoutes}
                        startingURL={url}
                    />
                </ModalProvider>
                <Toaster />
            </RpcClientProvider>
        </StrictMode>
    );
}
