import { Icon } from "@for-it/web-lib";
import { FORIT_EMAIL, WSP_MESSAGE, WSP_NUMBER } from "../constants.ts";

const Footer = () => {
    return (
        <footer
            className="text-white py-8 px-4 lg:px-24"
            style={{
                backgroundImage: `url(/images/footer-background.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
                    <div className="md:w-1/4 flex flex-col items-center md:items-start">
                        <div className="flex items-center space-x-3">
                            <img
                                src="/images/forit-logo-claro.svg"
                                alt="ForIT Logo"
                                className="w-32 md:w-36 lg:w-40 mt-2 h-auto"
                            />
                        </div>

                        <div className="mt-4">
                            <p
                                className="text-sm sm:text-base md:text-sm lg:text-lg flex items-center mt-2 cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        `https://wa.me/${WSP_NUMBER}?text=${encodeURIComponent(WSP_MESSAGE)}`,
                                        "_blank",
                                    )
                                }
                            >
                                <Icon
                                    name="bxl-whatsapp"
                                    className="text-lg mr-2"
                                />
                                {WSP_NUMBER}
                            </p>
                            <a
                                className="text-sm sm:text-base md:text-sm lg:text-lg flex items-center mt-2"
                                href={`mailto:${FORIT_EMAIL}`}
                            >
                                <Icon
                                    name="bx-mail-send"
                                    className="text-lg mr-2"
                                />
                                {FORIT_EMAIL}
                            </a>
                        </div>
                    </div>

                    <div className="md:w-1/3 flex flex-col items-center mt-6 md:mt-0">
                        <p className="text-sm sm:text-base md:text-lg lg:text-2xl">
                            Es una iniciativa de:
                        </p>
                        <a
                            href="https://fundacionformar.ar/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/images/formar-logo.svg"
                                alt="FORMAR ARGENTINA Logo"
                                className="w-32 md:w-36 lg:w-40 mt-2 h-auto"
                            />
                        </a>
                    </div>

                    <div className="md:w-1/4 flex flex-col items-center mt-6 md:mt-0">
                        <h2 className="text-sm sm:text-base md:text-lg lg:text-2xl font-bold">
                            ¡Seguinos!
                        </h2>
                        <div className="flex space-x-4 pt-2">
                            <a
                                href="https://www.linkedin.com/company/forit-software-factory/mycompany/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon
                                    name="bxl-linkedin"
                                    className="text-2xl"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-600 my-6"></div>

                <div className="text-center text-xs sm:text-sm lg:text-base">
                    <p>Desarrollado por ForIT y La Empujadora</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
