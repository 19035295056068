import { DefaultPermissions } from "@for-it/domain-lib";
import { UserRole } from "./user-role.js";
export const Permission = {
    ...DefaultPermissions,
    CREATE_PRODUCT: "CREATE_PRODUCT",
    EDIT_PRODUCT: "EDIT_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    GET_PRODUCT: "GET_PRODUCT",
    DELETE_PRODUCT_MEDIA: "DELETE_PRODUCT_MEDIA",
    VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
};
export const DefaultPermissionsByRole = {
    [UserRole.ADMIN]: [
        Permission.CREATE_PRODUCT,
        Permission.EDIT_PRODUCT,
        Permission.DELETE_PRODUCT,
        Permission.GET_PRODUCT,
        Permission.DELETE_PRODUCT_MEDIA,
        Permission.VIEW_ADMIN_DASHBOARD,
    ],
    [UserRole.REGULAR]: [],
};
